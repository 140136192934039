const hero = new Swiper('#hero', {
    slidesPerGroup: 1,
    slidesPerView: 1,
    loop: true,
    speed: 700,
    spaceBetween: '10px',
    autoplay: {
        delay: 6000,
    },
    grabCursor: true,
    keyboard: {
        enabled: true,
        onlyInViewport: false,
    },
    parallax: true,
    loop: true,
    pauseOnMouseEnter: true,
    navigation: {
        nextEl: '.hero_container .next',
        prevEl: '.hero_container .prev'
    },
    pagination: {
        el: '.hero_container .swiper-pagination',
        clickable: true,
        type: 'bullets',
    },
})